.hover-pointer :hover {
    cursor:pointer
}

.wl-text-primary {
    color: #0f2e49;
}

.wl-text-secondary {
    color: #00334a;
}

.wl-text-tertiary {
    color: #a6bf00;
}

.wl-text-quarternary {
    color: #3c3c43;
}

th {
    min-width: 100px;
}

.table-responsive {
    overflow: initial;
}